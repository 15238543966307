import http from "../http-common"
/* eslint-disable */
class AsetLokasiService {
  asetLokasiList(page, itemPerPage, keyword) {
    return http.get(
      "/aset-lokasi/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    )
  }

  asetLokasiListAll() {
    return http.get("/aset-lokasi/list/all", {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    })
  }

  create(data) {
    var bodyFormData = new FormData()
    bodyFormData.append("nama", data.nama)

    return http.post("/aset-lokasi", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
  }

  update(id, data) {
    var bodyFormData = new FormData()
    bodyFormData.append("id", id)
    bodyFormData.append("nama", data.nama)

    return http.put(`/aset-lokasi/${id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
  }

  delete(id) {
    return http.delete(`/aset-lokasi/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
  }
}

export default new AsetLokasiService()
