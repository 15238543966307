<template>
  <v-main>
    <!-- Provides the application the proper gutter -->
    <v-container fluid>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>

      <!-- If using vue-router -->
      <!-- <router-view></router-view> -->
      <v-card>
        <v-card-title>
          Lokasi Aset
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Cari nama"
            single-line
            hide-details
            v-on:keyup.enter="getDataFromApi"
          ></v-text-field>
          <v-spacer></v-spacer>

          <v-dialog v-model="dialog" persistent max-width="600px">
            <template v-slot:activator="{}">
              <v-btn color="primary" dark @click="doAdd"> Tambah </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">Lokasi Aset</span>
              </v-card-title>
              <v-card-text>
                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                  class="pl-4 pr-4"
                >
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          label="Nama Lokasi"
                          required
                          outlined
                          dense
                          v-model="currentData.nama"
                          :rules="namaRules"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog = false">
                  Close
                </v-btn>
                <v-btn color="blue darken-1" text @click="doSave"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Apa anda akan menghapus data ini?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Tidak</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >Ya</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="list_data"
          :options.sync="options"
          :server-items-length="totalData"
          :loading="loading"
          class="elevation-1"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon medium class="mr-3" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon medium @click="deleteItem(item)"> mdi-delete </v-icon>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import AsetLokasiService from "../../services/AsetLokasiService"
import CommonService from "../../services/CommonService"

export default {
  data: () => ({
    valid: false,
    breadcrumbs: [
      {
        text: "Data Lokasi Aset",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
    search: "",
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Lokasi", value: "nama", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 10,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,
    currentData: {
      id: null,
      nama: "",
    },
    namaRules: [(v) => !!v || "Nama harus diisi"],
  }),
  watch: {
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi()
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getDataFromApi()
  },
  methods: {
    getDataFromApi() {
      const { page, itemsPerPage } = this.options

      this.loading = true
      AsetLokasiService.asetLokasiList(page, itemsPerPage, this.search)
        .then((res) => {
          this.list_data = res.data.list_data
          this.totalData = res.data.total
          this.loading = false
          this.isFirst = true
        })
        .catch((err) => {
          this.loading = false
          this.isFirst = true
          console.log(err)
        })
    },

    doAdd: async function () {
      this.dialog = true
      this.currentData.nama = ""
      this.currentData.id = null
      console.log("do add")
    },

    doSave: async function () {
      if (!this.$refs.form.validate()) {
        window.scrollTo(0, 0)
        return
      }

      if (this.currentData.id == null) {
        AsetLokasiService.create(this.currentData)
          .then((res) => {
            console.log(res)
            this.dialog = false

            CommonService.showSuccessToast("Tambah lokasi berhasil")

            this.getDataFromApi()
          })
          .catch((err) => {
            console.log(err)
            CommonService.showErrorToast("Tambah lokasi gagal. Coba lagi")
          })
      } else {
        AsetLokasiService.update(this.currentData.id, this.currentData)
          .then((res) => {
            this.dialog = false

            console.log(res)
            CommonService.showSuccessToast("Update lokasi berhasil")

            this.getDataFromApi()
          })
          .catch((err) => {
            console.log(err)
            CommonService.showErrorToast("Update lokasi gagal. Coba lagi")
          })
      }
    },

    editItem(item) {
      console.log(item)
      this.editedIndex = this.list_data.indexOf(item)
      this.editedItem = Object.assign({}, item)

      this.dialog = true
      this.currentData.nama = this.editedItem.nama

      this.currentData.id = this.editedItem.id
      console.log("do add")
    },

    deleteItem(item) {
      console.log(item)
      this.editedIndex = this.list_data.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.closeDelete()

      AsetLokasiService.delete(this.editedItem.id)
        .then((res) => {
          console.log(res)

          CommonService.showSuccessToast("Hapus lokasi berhasil")

          this.getDataFromApi()
        })
        .catch((err) => {
          console.log(err)
          CommonService.showSuccessToast("Hapus lokasi gagal. Coba lagi")
        })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
  },
}
</script>
